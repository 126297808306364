.App {
  width: 100vw;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.image-in-grid {
  width: 300px;
}

.image-file-upload-container {
  padding: 30px 70px;
  background-color: #e4e4e4;
  margin: 0px auto 20px auto;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.img-container {
  max-height: 168px;
  max-width: 300px;
  margin: 10px;
  overflow: hidden;
}

.img-and-desc-container {
  background-color: rgb(247, 247, 247);
  box-shadow: rgb(0 0 0 / 36%) 0px 5px 15px 0px;
  /* min-height: 250px; */
  margin: 10px;
  border-radius: 10px;
  transition: 0.4s;
}

.img-and-desc-container:hover {
  background-color: rgb(204 227 233);
}

.img-parent {
  display: flex;
  flex-wrap: wrap;
  padding: 0px 60px;
}

.img-text{
  margin: 10px;
  width: 300px;
}

.img-subtext {
  font-size: 11px;
}

.img-icons-container {
  margin: 0px 0px 20px 10px;
}

.img-icons {
  width: 30px;
  margin-right: 6px;
  opacity: 60%;
}

.img-icons:hover {
  opacity: 100%;
}

.image-selected { 
  margin: 10px 0px;
  max-height: 300px;
  max-width: 300px !important;
  box-shadow: rgb(0 0 0 / 36%) 0px 5px 15px 0px;
}

.image-input-title {
  margin-top: 20px;
}

.image-search-bar-container {
  /* width: 436px; */
  padding: 10px 70px;
}

.image-search-bar {
  border: 1px solid #c6c6c6;
    box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 2px 6px 2px;

}

.login-page{
  height: 100vh;
  display: flex;
}

.login-container{
  width: 600px;
  margin: 0px auto;
  height: 500px;
  padding: 50px 0px;
  background-color: white;
  background-color: #fffffff2;
  border-radius: 27px;
  align-self: center;
  box-shadow: 0 0 60px 30px hsl(0deg 0% 100% / 50%), 0 0 100px 60px rgb(255 0 255 / 50%), 0 0 140px 90px rgb(0 255 255 / 50%);
  border: 10px solid #fff;
  display: grid;
}

.title-of-page {
  padding-bottom: 20px;
}

.title-selected {
  color: green;
}

.image-modal {
  max-width: 400px;
  max-height: 400px;
  margin-bottom: 15px;
  box-shadow: rgb(0 0 0 / 36%) 0px 5px 15px 0px;
}

.info-container {
  margin: 10px 70px;
  padding: 20px;
  background-color: #fff5ea;
  border-radius: 11px;
}

.image-edit-function-container{
  margin: 1rem;
}

.change-title-container{
  background-color: rgb(235, 235, 235);
  margin: 1rem 0rem;
  padding: 1rem;
  border-radius: 10px;
}

.replace-image-container{
  background-color: rgb(235, 235, 235);
  padding: 1rem;
  border-radius: 10px;
}

.custom-card {
  background-color: #2d278de0 !important;
}